<template>
  <div class="choicesupplier">
    <el-form ref="choiceSupplier" :model="supplierData" :rules="formRules" :class="{isDisabled:formDisabled}" label-width="120px">
        <el-form-item label="邀请供应商" :prop="propData">
            <el-button v-if="!formDisabled" size="small" type="primary" @click="chooseSupplier" plain>+选择供应商</el-button>
            <div class="tableList">
              <div class="table">
                <el-table border class="table" :data="supplierData.bidSupplierList||supplierData.inquirySupplierList" style="width: 100%">
                  <el-table-column label="序号" width="50px">
                    <template #default="scope">
                      {{scope.$index+1}}
                    </template>
                  </el-table-column>
                  <el-table-column :show-overflow-tooltip="true" prop="erpCode" label="供应商编码" width="width"></el-table-column>
                  <el-table-column :show-overflow-tooltip="true" prop="name" label="供应商名称" width="width"></el-table-column>
                  <el-table-column :show-overflow-tooltip="true" prop="supplierContactName" label="联系人" width="width"></el-table-column>
                  <el-table-column :show-overflow-tooltip="true" prop="supplierContactPhone" label="手机号" width="width"></el-table-column>
                  <el-table-column :show-overflow-tooltip="true" prop="supplierContactMail" label="邮箱" width="width"></el-table-column>
                  <el-table-column :show-overflow-tooltip="true" prop="isAccept" label="是否应标" width="width">
                     <template #default="scope">
                     {{scope.row.isAccept===1?"是":"否"}}
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
        </el-form-item>
    </el-form>
    <dialog-box ref="dialogbox" dialogWidth="900px" :dialogShow="dialogShow" componentName="ChoiceSupplierBody"
            @handleClose='handleClose' @cancel="handleClose" title="选择供应商"
             @addSupplier="sureChoice" :choiceData="choiceData" :buttonData="buttonData"
             :choiced="choiced">
    </dialog-box>
  </div>
</template>

<script>
import { request } from '@/assets/js/http.js'
import Component from '@/assets/js/components.js'
export default {
  name: 'InvitingSuppliers',
  components: Component.components,
  props: ['formData', 'dataFormat'],
  data () {
    var choiceddata = []
    if (this.formData.bidSupplierList) {
      for (var i = 0; i < this.formData.bidSupplierList.length; i++) {
        choiceddata.push(this.formData.bidSupplierList[i].code)
      }
    } else if (this.formData.inquirySupplierList) {
      for (var s = 0; s < this.formData.inquirySupplierList.length; s++) {
        choiceddata.push(this.formData.inquirySupplierList[s].code)
      }
    }
    return {
      supplierData: this.formData,
      dialogShow: false,
      supplierAllData: [],
      buttonData: [{ label: '取消', action: 'cancel', size: 'mini' }, { label: '确认', action: 'addSupplier', size: 'mini', type: 'primary' }],
      choiceData: [],
      propData: 'bidSupplierList',
      choiced: choiceddata,
      formRules: this.dataFormat ? this.dataFormat.rules : {},
      formDisabled: false
    }
  },
  mounted: function () {
    if (this.dataFormat) {
      if (this.dataFormat.formDisabled) {
        this.formDisabled = true
      }
    }
    if (this.formData) {
      if (this.formData.bidSupplierList) {
        this.propData = 'bidSupplierList'
      } else {
        this.propData = 'inquirySupplierList'
      }
    }
  },
  watch: {
    formData: function () {
      this.supplierData = this.formData
      var choiceddata = []
      if (this.formData.bidSupplierList) {
        this.propData = 'bidSupplierList'
        for (var i = 0; i < this.formData.bidSupplierList.length; i++) {
          choiceddata.push(this.formData.bidSupplierList[i].code)
        }
      } else {
        this.propData = 'inquirySupplierList'
        for (var s = 0; s < this.formData.inquirySupplierList.length; s++) {
          choiceddata.push(this.formData.inquirySupplierList[s].code)
        }
      }
      this.choiced = choiceddata
    }
  },
  methods: {
    chooseSupplier () {
      request('/api/supplier/basicData/getListNoPage', 'get', { businessScope: this.formData.businessScope }).then((Response) => {
        if (Response.code === '200') {
          this.choiceData = []
          var data = [...Response.data]
          this.supplierAllData = data
          for (var i = 0; i < data.length; i++) {
            data[i].label = data[i].name + ' ' + data[i].erpCode
            data[i].key = data[i].id
            this.choiceData.push(data[i])
          }
          this.dialogShow = true
        }
      })
    },
    handleClose () {
      this.dialogShow = false
    },
    sureChoice () {
      var data = this.$refs.dialogbox.$refs.content.choicedData
      var choicedData = []
      const newArry = []
      this.dialogVisible = false
      for (let i = 0; i < this.supplierAllData.length; i++) {
        for (let j = data.length; j >= 0; j--) {
          if (this.supplierAllData[i].id === data[j]) {
            var tmpData = JSON.parse(JSON.stringify(this.supplierAllData[i]))
            tmpData.code = tmpData.id
            choicedData.push(tmpData.id)
            delete tmpData.id
            newArry.push(tmpData)
          }
        }
      }
      this.choiced = choicedData
      if (this.supplierData.bidSupplierList) {
        this.supplierData.bidSupplierList = newArry
      } else {
        this.supplierData.inquirySupplierList = newArry
      }
      this.dialogShow = false
    }
  }
}
</script>

<style scoped lang='scss'>
@import '@/assets/css/tableList.scss';
.choicesupplier{
  .tableList{
    padding: 0 0;
    :deep(.table){
      *{
        line-height: normal;
        }
    }
  }
  .table{
    margin-top: 20px;
  }
  .isDisabled{
    .table{
      margin-top: 14px;
    }
  }
}
</style>
